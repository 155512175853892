import { useState, useEffect } from "react"

// https://dev.to/reedbarger/how-to-create-a-usewindowsize-react-hook-2bcm
export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: 1200,
    height: 800,
  })

  const changeWindowSize = () => {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight })
  }

  useEffect(() => {
    changeWindowSize()
    window.addEventListener("resize", changeWindowSize)

    return () => {
      window.removeEventListener("resize", changeWindowSize)
    }
  }, [])

  return windowSize
}

export const writeSessionStorage = (key, value) => {
  if (typeof window !== "undefined") {
    sessionStorage.setItem(key, value);
  }
}

export const readSessionStorage = (key) => {
  if (typeof window !== "undefined") {
    return sessionStorage.getItem(key);
  }
}
