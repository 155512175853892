export const isEDMode =
  typeof window !== "undefined"
    ? window.location.href.indexOf("ed=1") > 0
    : false
export const bounds = elem => {
  const rect = elem.getBoundingClientRect()

  return {
    top: rect.top,
    left: rect.left,
    right: rect.right,
    bottom: rect.bottom,
    width: rect.width,
    height: rect.height,
  }
}

export const getWinSize = () => {
  if (typeof window !== "undefined") {
    const w = window
    const d = document
    const e = d.documentElement
    const g = d.getElementsByTagName("body")[0]
    const x = w.innerWidth || e.clientWidth || g.clientWidth
    const y = w.innerHeight || e.clientHeight || g.clientHeight

    return { width: x, height: y }
  }

  return { width: 0, height: 0 }
}

export const inViewport = (
  elem,
  entireBox = false,
  xOffset = 0,
  yOffset = 0
) => {
  if (typeof window !== "undefined") {
    const bds = bounds(elem)
    const doc = getWinSize()

    if (!entireBox) {
      return (
        bds.top >= 0 &&
        bds.left >= 0 &&
        bds.top <= doc.height &&
        bds.left <= doc.width
      )
    } else {
      const topLimit = bds.top - doc.height
      const bottomLimit = bds.top + bds.height
      const leftLimit = bds.left - doc.width
      const rightLimit = bds.left + bds.width

      if (
        topLimit <= 0 &&
        bottomLimit >= 0 &&
        leftLimit <= 0 &&
        rightLimit >= 0
      ) {
        return true
      } else {
        return false
      }
    }
  }

  return false
}

export const debounce = (func, wait, immediate) => {
  let timeout = null

  return function () {
    const context = this
    const args = arguments

    const later = () => {
      timeout = null

      if (!immediate) {
        func.apply(context, args)
      }
    }

    const callNow = immediate && !timeout

    clearTimeout(timeout)

    timeout = setTimeout(later, wait)

    if (callNow) {
      func.apply(context, args)
    }
  }
}

export const offset = elem => {
  if (typeof window !== "undefined") {
    const rect = bounds(elem)
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop

    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
  }

  return { top: 0, left: 0 }
}

export const focusLoop = (e, selector) => {
  const tabPressed = e.key === "Tab" || e.keyCode === 9

  if (!tabPressed) return

  const elems = document.querySelectorAll(selector)

  if (elems?.length) {
    const firstElem = elems[0]
    const lastElem = elems[elems.length - 1]

    if (!Array.from(elems).includes(document.activeElement)) {
      firstElem.focus()
    }

    if (e.shiftKey) {
      if (document.activeElement === firstElem) {
        lastElem.focus()
        e.preventDefault()
      }
    } else {
      if (document.activeElement === lastElem) {
        firstElem.focus()
        e.preventDefault()
      }
    }
  }
}

export const getUrlParameter = param => {
  param = param.replace(/[\\[]/, "\\[").replace(/[\]]/, "\\]")
  const regex = new RegExp("[\\?&]" + param + "=([^&#]*)")
  const results = regex.exec(window.location.search)

  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "))
}

// TODO: prevent scroll on iOS devices

export const lockScroll = () => {
  const paddingRight = window.innerWidth - document.body.offsetWidth;
  const top = window.pageYOffset;

  document.body.setAttribute("data-state", "locked");
  document.body.style.overflow = "hidden";
  document.body.style.position = "fixed";
  document.body.style.paddingRight = `${paddingRight}px`;
  document.body.style.top = -top + "px";
  document.body.style.right = 0;
  document.body.style.bottom = 0;
  document.body.style.left = 0;

  // prevent scroll on iOS
  // document.body.addEventListener("touchstart", preventTouchScroll, { passive: false });
  // document.body.addEventListener("touchmove", preventTouchScroll, { passive: false });
};

export const resetScroll = () => {
  const currentScrollY = document.body.style.top.replace("-", "").replace("px", "");

  document.body.style.overflow = "";
  document.body.style.position = "";
  document.body.style.paddingRight = "";
  document.body.style.top = "";
  document.body.style.right = "";
  document.body.style.bottom = "";
  document.body.style.left = "";
  window.scrollTo({ top: currentScrollY, behavior: "instant" });
  document.body.removeAttribute("data-state");

  // iOS
  // document.body.removeEventListener("touchstart", preventTouchScroll);
  // document.body.removeEventListener("touchmove", preventTouchScroll);
};

// function preventTouchScroll(e) {
//     e.preventDefault();
// }

export const scrollToEl = (el) => {
  // TODO: remove setTimeout when ISI is reworked so that it's always postition: static at the bottom. (need to separate StickyISI and BottomISI)
  setTimeout(() => {
    const elTop = document.querySelector(el).offsetTop;
    window.scrollTo({
      top: elTop,
      behavior: "smooth",
    });
  }, 1000);
};

export const openExitModal = (e) => {
  e.preventDefault();
  localStorage.setItem("exitModal", e.target.href);
  window.dispatchEvent(new Event("storageChange"));
};
