/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */
import React from "react";
import GTM from "@utils/GTM"
import { AppProvider } from "@components/AppContext";

// Supports weights 100-900
import "@fontsource-variable/montserrat"

import "@fontsource/poppins/400.css"
import "@fontsource/poppins/700.css"

import "./src/styles/global.css"
import "./src/styles/screenshots.css"

export const onRouteUpdate = () => {
  GTM.scroll(true);
};

export const wrapRootElement = ({ element }) => (
  <AppProvider>
    {element}
  </AppProvider>
);
