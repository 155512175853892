exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-efficacy-js": () => import("./../../../src/pages/efficacy.js" /* webpackChunkName: "component---src-pages-efficacy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-safety-js": () => import("./../../../src/pages/safety.js" /* webpackChunkName: "component---src-pages-safety-js" */),
  "component---src-pages-screenshots-js": () => import("./../../../src/pages/screenshots.js" /* webpackChunkName: "component---src-pages-screenshots-js" */),
  "component---src-pages-using-xacduro-js": () => import("./../../../src/pages/using-xacduro.js" /* webpackChunkName: "component---src-pages-using-xacduro-js" */),
  "component---src-pages-why-js": () => import("./../../../src/pages/why.js" /* webpackChunkName: "component---src-pages-why-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

