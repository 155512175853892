import { debounce } from "./helper";

// Page Scroll
let distanceScrolled = -1;
let distanceThreshold = [
    { threshold: 0, value: "Baseline", sent: false },
    { threshold: 25, value: "25%", sent: false },
    { threshold: 50, value: "50%", sent: false },
    { threshold: 75, value: "75%", sent: false },
    { threshold: 100, value: "100%", sent: false },
];

const scrollDepth = () => {
    const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
    let progress = Math.round((scrollTop / (document.body.scrollHeight - window.innerHeight)) * 100);
    if (isNaN(progress)) progress = 0;

    if (progress > distanceScrolled) {
        distanceScrolled = progress;

        for (let i = 0; i < distanceThreshold.length; i++) {
            const status = distanceThreshold[i].sent;
            const threshold = distanceThreshold[i].threshold;
            const value = distanceThreshold[i].value;

            if (!status && progress >= threshold) {
                if ("dataLayer" in window) {
                    window.dataLayer.push({ event: "gatsbyScroll", gatsbyScrollDistance: value });
                    distanceThreshold[i].sent = true;
                }
            }
        }
    }
};

const scrollListener = debounce(scrollDepth, 18);

// ISI Scroll
let distanceScrolledIsi = -1;
let distanceThresholdIsi = [
    { threshold: 0, value: "Baseline", sent: false },
    { threshold: 25, value: "25%", sent: false },
    { threshold: 50, value: "50%", sent: false },
    { threshold: 75, value: "75%", sent: false },
    { threshold: 100, value: "100%", sent: false },
];

const scrollDepthIsi = () => {
    const isi = document.querySelector("[data-gtm-label='isi-sticky-tray']");
    const scrollTop = isi.scrollTop;

    let progress = Math.round((scrollTop / (isi.scrollHeight - isi.clientHeight)) * 100);
    if (isNaN(progress)) progress = 0;

    if (progress > distanceScrolledIsi) {
        distanceScrolledIsi = progress;

        for (let i = 0; i < distanceThresholdIsi.length; i++) {
            const status = distanceThresholdIsi[i].sent;
            const threshold = distanceThresholdIsi[i].threshold;
            const value = distanceThresholdIsi[i].value;

            if (!status && progress >= threshold) {
                if ("dataLayer" in window) {
                    window.dataLayer.push({
                        event: "isiScroll",
                        isiScrollDistance: value,
                    });
                    distanceThresholdIsi[i].sent = true;
                }
            }
        }
    }
};

const scrollListenerIsi = debounce(scrollDepthIsi, 18);

const GTM = {
    init: () => {
        const interval = setInterval(() => {
            if ("dataLayer" in window && "google_tag_manager" in window) {
                window.dataLayer.push({ event: "gatsby-route-change" });
                clearInterval(interval);
            }
        }, 100);
    },
    initIsi: () => {
        scrollDepthIsi();
        const isi = document.querySelector("[data-gtm-label='isi-sticky-tray']");
        if (isi) {
            isi.addEventListener("scroll", scrollListenerIsi);
        }
    },
    scroll: (resetScroll) => {
        if (resetScroll) {
            distanceScrolled = -1;
            distanceThreshold.forEach((threshold) => (threshold.sent = false));
        }
        scrollDepth();
        document.addEventListener("scroll", scrollListener);
    },
    formStart: () => {
        if ("dataLayer" in window) {
            window.dataLayer.push({ event: "formStart" });
        }
    },
    fieldCompletion: (fieldName, fieldValue) => {
        if ("dataLayer" in window) {
            window.dataLayer.push({ event: "fieldCompletion", fieldName, fieldValue });
        }
    },
    fieldError: (fieldName, fieldErrorValue) => {
        if ("dataLayer" in window) {
            window.dataLayer.push({ event: "fieldError", fieldName, fieldErrorValue });
        }
    },
    formSubmission: () => {
        if ("dataLayer" in window) {
            window.dataLayer.push({ event: "formCompletion" });
        }
    },
    reset: () => {
        distanceScrolled = -1;
        distanceThreshold.forEach((threshold) => (threshold.sent = false));
        distanceScrolledIsi = -1;
        distanceThresholdIsi.forEach((threshold) => (threshold.sent = false));
    },
};

export default GTM;
