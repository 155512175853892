import React, { createContext, useState, useEffect } from "react";
import { writeSessionStorage, readSessionStorage } from '@components/Utils';

const defaultState = {};

const AppContext = createContext(defaultState);

const AppProvider = ({ children }) => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const bannerState = readSessionStorage('showSisBanner') !== 'false';
    setShowBanner(bannerState);
  }, []);

  const closeBanner = () => {
    writeSessionStorage('showSisBanner', 'false');
    setShowBanner(false);
  }

  const store = {
    showBanner,
    closeBanner,
  };

  return <AppContext.Provider value={store}>{children}</AppContext.Provider>;
};

export default AppContext;

export { AppProvider };
